import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FooterWidget() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="bg-black w-screen z-20">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
      <div className="container mx-auto py-5 px-5 text-white ">
        <div className="w-full flex md:flex-row flex-col-reverse items-center text-center md:text-sm text-xs">
          <div className="md:w-2/3 w-full">
            The Marrakech English Book Festival is organized by the Marrakech
            English Book Association and is co-hosted with our venue partner
            Moroccan Foundation Nationale des Musées and our accommodation
            partner, the JAAL Riad Resort Marrakech, with the support of our
            corporate sponsors Travel Link and Aynnaka. The Costa Goldex Book
            Award & short Story Competition is organized and supported by our
            partners Goldex Morocco and the Olveseed Foundation. The MEBF is
            also closely supported by our education partners The British Academy
            School Marrakech, the Cadi Ayyad and UM6P Universities.
          </div>
          <div className="md:w-1/3 w-full px-5 mb-3">
            <h2 class="text-xl font-bold mb-3 text-white">
              Subscribe to Our Newsletter
            </h2>
            <p class="text-xs text-gray-300">
              Stay updated with our latest news & updates. Dont miss out on our
              exciting journey.
            </p>
            <div class="mt-5 flex items-center overflow-hidden bg-gray-50 rounded-md  mx-auto">
              <input
                type="email"
                value={email}
                onChange={(v) => setEmail(v.target.value)}
                placeholder="Enter your email"
                class="w-full bg-transparent py-2 px-3 text-gray-800 text-sm focus:outline-none"
              />
              <button
                disabled={isLoading}
                onClick={async () => {
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                  if (email === "") {
                    toast.error("Please enter your email.");
                  } else if (!emailRegex.test(email)) {
                    toast.error("Please enter your valid email address.");
                  } else {
                    setIsLoading(true);
                    try {
                      const response = await axios.post(
                        "https://api.mebookfest.com/api/messages/subscribe/",
                        {
                          email: email ?? "",
                        }
                      );
                      toast.success(
                        "Thank you for subscribing to our website. Stay tuned for updates!"
                      );
                      setIsLoading(false);
                    } catch (error) {
                      setIsLoading(false);
                      toast.error(
                        "Your subscribing was not sent. Please try again. "
                      );
                    }
                  }
                }}
                class="bg-red-500 flex flex-row item-center hover:bg-red-700 text-white text-sm tracking-wide py-2 px-3 hover:shadow-md hover:transition-transform transition-transform hover:scale-105 focus:outline-none"
              >
                <div className="mx-1">Subscribe</div>
                {isLoading ? (
                  <div role="status mx-1">
                    <svg
                      aria-hidden="true"
                      className="size-4 text-gray-200 animate-spin  fill-black"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
          </div>
        </div>
        <div className="h-14"></div>
      </div>
    </div>
  );
}

export default FooterWidget;
