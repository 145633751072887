import React from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";

function PassesTicketsScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white">
            <div className="font-bold text-2xl mt-10 mb-5 text-center">
              You can buy your festival tickets here!
            </div>
            <div className="my-2 text-center md:px-5 md:text-base text-sm ">
              There are two types of tickets available: a free ticket for
              students and a general admission ticket. The student ticket is
              limited and offered on a first-come, first-served basis. Students
              must enter their student ID number during registration and present
              it on the day of the event for access. The general admission
              ticket provides access to the festival and includes an exclusive
              tour of the DAR BACHA MUSEUM. Secure your ticket now to join the
              celebration!
            </div>

            <div className="my-2 flex md:flex-row flex-col md:px-5">
              <a
                href="https://aylink.ma/p/mebf-2025/festival-ticket-635463"
                target="_blank"
                rel="noopener noreferrer"
                className="md:w-1/2 w-full text-center px-5 py-3"
              >
                <div className="font-extrabold  ">General Admission Ticket</div>
                <img src="/images/MEBF-2025-ticket.png" className="" />
              </a>
              <a
                href="https://aylink.ma/p/mebf-2025/student-ticket-318321"
                target="_blank"
                rel="noopener noreferrer"
                className="md:w-1/2 w-full text-center px-5 py-3"
              >
                <div className=" font-extrabold  ">Student Ticket</div>
                <img src="/images/MEBF-2025-ticket.png" className="" />
              </a>
            </div>
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default PassesTicketsScreen;
