import React, { useEffect, useState } from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";
import { AUTHORSLIST } from "../constants";

function AuthorInfoScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  let { slug } = useParams();
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    setAuthor(findItemBySlug(slug));
  }, []);

  const findItemBySlug = (slug) => {
    return AUTHORSLIST.find((item) => item.slug === slug);
  };

  const [indexBook, setIndexBook] = useState(0);

  const getNextSlug = (slug) => {
    const currentIndex = AUTHORSLIST.findIndex((item) => item.slug === slug);
    const next =
      currentIndex < AUTHORSLIST.length - 1
        ? AUTHORSLIST[currentIndex + 1]
        : null;
    return next;
  };

  const getPreviousSlug = (slug) => {
    const currentIndex = AUTHORSLIST.findIndex((item) => item.slug === slug);
    const previous = currentIndex > 0 ? AUTHORSLIST[currentIndex - 1] : null;
    return previous;
  };

  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col ">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container md:mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white">
            {author ? (
              <>
                <div className="flex flex-row justify-between items-center">
                  <div>
                    {getPreviousSlug(author.slug) != null ? (
                      <a href={"/authors/" + getPreviousSlug(author.slug).slug}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-9 hover:text-red-600"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                          />
                        </svg>
                      </a>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-9 text-gray-300"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                        />
                      </svg>
                    )}
                  </div>
                  <div>
                    {getNextSlug(author.slug) != null ? (
                      <a href={"/authors/" + getNextSlug(author.slug).slug}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-9 hover:text-red-600"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                          />
                        </svg>
                      </a>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-9 text-gray-300"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                <div className="my-5 flex flex-row items-center justify-center">
                  <div className="w-1/2 flex flex-row items-center justify-end">
                    <img
                      className={`p-2 right-0 md:w-2/3`}
                      src={author.image}
                      alt={author.name}
                    />
                  </div>
                  <div className="w-1/2 flex flex-row items-center justify-start ">
                    <div className="md:w-2/3 left-0 relative">
                      <img
                        className={`p-2 `}
                        src={author.images[indexBook]}
                        alt={author.name}
                      />
                      {author.images.length > 1 ? (
                        <button
                          onClick={() => {
                            if (indexBook === 0) {
                              setIndexBook(author.images.length - 1);
                            } else {
                              setIndexBook(indexBook - 1);
                            }
                          }}
                          className="shadow-lg rounded-full bg-white absolute top-1/2 p-2 left-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.75 19.5 8.25 12l7.5-7.5"
                            />
                          </svg>
                        </button>
                      ) : null}
                      {author.images.length > 1 ? (
                        <button
                          onClick={() => {
                            if (indexBook === author.images.length - 1) {
                              setIndexBook(0);
                            } else {
                              setIndexBook(indexBook + 1);
                            }
                          }}
                          className="shadow-lg rounded-full bg-white absolute top-1/2 p-2 right-2 "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="size-5"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                          </svg>
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="font-bold text-center text-xl mt-5 mb-5">
                  {author.name}
                </div>
                <div
                  className={`flex md:flex-row flex-col ${
                    author?.images?.length > 1 ? "items-center" : "items-start"
                  } `}
                >
                  {/* <div
                    className={`md:w-1/3 w-full px-3 flex md:flex-col flex-wrap`}
                  >
                    {author && (
                      <img
                        className={`p-2 md:w-full  mx-auto ${
                          author?.images?.length > 1 ? "w-[80%]" : "w-1/2"
                        }`}
                        src={author.image}
                        alt={author.name}
                      />
                    )}
                    {author?.images?.length <= 1
                      ? author?.images?.map((image, index) => (
                          <img
                            className="p-2 md:w-full w-1/2"
                            src={image}
                            alt={index}
                          />
                        ))
                      : null}
                    
                  </div> */}
                  <div className=" w-full px-3">
                    <div>
                      {author.bio?.map((item, index) => (
                        <div className="my-2">{item}</div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div className="flex flex-wrap my-5 justify-center ">
                    {author?.images?.length > 1
                      ? author?.images?.map((image, index) => (
                          <img
                            className="p-2 md:w-1/4 w-1/2"
                            src={image}
                            alt={index}
                          />
                        ))
                      : null}
                  </div>
                </div> */}
              </>
            ) : null}
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default AuthorInfoScreen;
