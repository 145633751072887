import React from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";
const events = [
  { year: "2022", month: "January", description: "Started a new project" },
  { year: "2022", month: "May", description: "Launched version 1.0" },
  { year: "2023", month: "February", description: "Reached 10,000 users" },
  // Add more events as needed
];

function ProgrammeScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-fixed bg-cover bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white">
            <div className="font-bold text-2xl mt-10 mb-5 text-center">
              The Marrakech English Book Festival
            </div>
            <div className="">
              <section>
                <div className="py-2 ">
                  <div className=" mx-auto flex flex-col items-start md:flex-row my-2 ">
                    <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2  px-2">
                      <p className="ml-2 font-bold uppercase tracking-loose">
                        Friday 17th January 2025
                      </p>
                      <p className="text-xs leading-normal md:leading-relaxed mb-1">
                        10h00–10h30 Opening ceremony
                      </p>
                      <p className="text-xs  mb-2">
                        Main events will be held at our partner venue, the
                        Moroccan National Museum Foundation’s Dar El Bacha
                        Museum of Confluences.
                      </p>
                    </div>
                    <div className="ml-0 lg:w-2/3 w-full sticky bg-red-500 rounded">
                      <div className="container mx-auto w-full h-full">
                        <div className="relative wrap overflow-hidden p-2 h-full">
                          <div
                            className="border-2-2 border-[#fff]  border-yellow-555 absolute h-full "
                            style={{
                              right: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div
                            className="border-2-2 border-yellow-555 absolute h-full border"
                            style={{
                              left: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline ">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right ">
                              <p className="text-xs">10h30 – 11h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Jane Green
                              </h4>
                              <p className="text-sm  leading-snug ">
                                The Magic of Marrakech in the Sixties
                              </p>
                            </div>
                          </div>
                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4 text-left">
                              <p className="text-xs">11h30 – 12h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Mhani Alaoui
                              </h4>
                              <p className="text-sm  leading-snug ">
                                Gender, Heritage and Class Relations in the
                                Novel
                              </p>
                            </div>
                          </div>
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right">
                              <p className="text-xs">14h30 – 15h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Fiona Valpy
                              </h4>
                              <p className="text-sm  leading-snug ">
                                Dual timeline fiction: contrast, congruences and
                                connections
                              </p>
                            </div>
                          </div>

                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4">
                              <p className="text-xs">15h30 – 16h30</p>
                              <h4 className="my-1 font-bold text-white"></h4>
                              <p className="text-sm  leading-snug ">
                                Moroccan Poets - a culture in verse. Hassan
                                Hilmy, Hassan Mekouar, Mbarek Sryfi, Hafsa
                                Bekri. Moderated by Alexandra Balafrej, British
                                Council Morocco Director
                              </p>
                            </div>
                          </div>
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-4 text-right">
                              <p className="text-xs">16h30 – 17h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Abigail Assor
                              </h4>
                              <p className="text-sm  leading-snug ">
                                As Rich as the King; A bittersweet love letter
                                to Casablanca
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*  */}
              <section>
                <div className="py-2 ">
                  <div className=" mx-auto flex flex-col items-start md:flex-row my-2 ">
                    <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2  px-2">
                      <p className="ml-2 font-bold uppercase tracking-loose">
                        Saturday 18th January 2025
                      </p>
                      <p className="text-xs leading-normal md:leading-relaxed mb-1"></p>
                      <p className="text-xs  mb-2">
                        There will be a day of children’s literary events
                        organised by the partner of MEBF, the British Academy
                        School of Marrakech. This will feature sessions
                        organised by authors and illustrators Merdohkt Amini,
                        Hannah Ait Ahmed and Purdeet Bhandal.
                      </p>
                    </div>
                    <div className="ml-0 lg:w-2/3 w-full sticky bg-red-500 rounded">
                      <div className="container mx-auto w-full h-full">
                        <div className="relative wrap overflow-hidden p-2 h-full">
                          <div
                            className="border-2-2 border-[#fff]  border-yellow-555 absolute h-full "
                            style={{
                              right: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div
                            className="border-2-2 border-yellow-555 absolute h-full border"
                            style={{
                              left: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline ">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right ">
                              <p className="text-xs">10h00 – 11h00</p>
                              <h4 className="my-1 font-bold text-white">
                                Laila Lalami
                              </h4>
                              <p className="text-sm  leading-snug ">
                                Laila Lalami in conversation with Mbarek Sryfi
                              </p>
                            </div>
                          </div>
                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4 text-left">
                              <p className="text-xs">11h30 – 12h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Amira K Bennison
                              </h4>
                              <p className="text-sm  leading-snug ">
                                Marrakesh and medieval ideas of Maghribi Islamic
                                Empire
                              </p>
                            </div>
                          </div>
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right">
                              <p className="text-xs">14h30 – 15h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Hassan Mekouar
                              </h4>
                              <p className="text-sm  leading-snug ">
                                Soft Sue and Other (Goodbye) Poems: The
                                Writing-Reading Interface
                              </p>
                            </div>
                          </div>

                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4">
                              <p className="text-xs">15h30 – 16h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Fouad Laroui
                              </h4>
                              <p className="text-sm  leading-snug ">
                                A Tale of Love and War
                              </p>
                            </div>
                          </div>
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right">
                              <p className="text-xs">16h30 – 17h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Hassan Mekouar
                              </h4>
                              <p className="text-sm  leading-snug ">
                                Can literature bridge the gap between cultures?
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/*  */}
              <section>
                <div className="py-2 ">
                  <div className=" mx-auto flex flex-col items-start md:flex-row my-2 ">
                    <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2  px-2">
                      <p className="ml-2 font-bold uppercase tracking-loose">
                        Sunday 19th January 2025
                      </p>
                      <p className="text-xs leading-normal md:leading-relaxed mb-1">
                        1700 – 1800 : Closing ceremony
                      </p>
                      <p className="text-xs  mb-2">
                        There will be a morning of events organised for local
                        children, featuring contributions from Merdohkt Amini,
                        Hannah Ait Ahmed and Purdeet Bhandal. This will take
                        place at Dar El Bellarj.
                        <br />
                        There will also be an afternoon of professional
                        workshops aimed at connecting and upskilling Moroccan
                        children’s authors, illustrators and designers while
                        introducing the Moroccan branch of The International
                        Board on Books for Young People (IBBY), a non-profit
                        organization which represents an international network
                        of people from all over the world who are committed to
                        bringing books and children together.
                      </p>
                    </div>
                    <div className="ml-0 lg:w-2/3 w-full sticky bg-red-500 rounded">
                      <div className="container mx-auto w-full h-full">
                        <div className="relative wrap overflow-hidden p-2 h-full">
                          <div
                            className="border-2-2 border-[#fff]  border-yellow-555 absolute h-full "
                            style={{
                              right: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div
                            className="border-2-2 border-yellow-555 absolute h-full border"
                            style={{
                              left: "50%",
                              border: " 2px solid #fff",
                              borderRadius: "1%",
                            }}
                          ></div>
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline ">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right ">
                              <p className="text-xs">10h00 – 11h00</p>
                              <h4 className="my-1 font-bold text-white">
                                Jane Johnson
                              </h4>
                              <p className="text-sm  leading-snug ">
                                How Barbary pirate raids in Cornwall in 1625 led
                                me to Morocco
                              </p>
                            </div>
                          </div>
                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4 text-left">
                              <p className="text-xs">11h30 – 12h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Hassan Hilmy
                              </h4>
                              <p className="text-sm  leading-snug ">
                                Digressive Jottings: Three Decades of Writing
                              </p>
                            </div>
                          </div>
                          <div className="m flex justify-between flex-row-reverse items-center w-full left-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1 w-[46%] px-1 py-2 text-right">
                              <p className="text-xs">14h30 – 15h30</p>
                              <h4 className="my-1 font-bold text-white">
                                Mbarek Sryfi
                              </h4>
                              <p className="text-sm  leading-snug ">
                                Translation as Soft Power" in conversation with
                                Marcia Lynx Qualey
                              </p>
                            </div>
                          </div>

                          <div className="m flex justify-between items-center w-full right-timeline">
                            <div className="order-1 w-[46%]"></div>
                            <div className="order-1  w-[46%] px-1 py-4">
                              <p className="text-xs">15h30 – 17h00</p>
                              <h4 className="my-1 font-bold text-white">
                                Elhoussain Alamimane
                              </h4>
                              <p className="text-sm  leading-snug ">
                                "Open Mic" Sessions - writers introduce their
                                work to the audience for 10 minutes each
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
      {/*  */}

      {/*  */}
    </div>
  );
}

export default ProgrammeScreen;
